export default {
  routes: [
    {
      path: '/binds',
      name: 'binds',
      component: () => import(/* webpackChunkName: "about" */ '@/views/binds/BindsFront.vue')
    },
    {
      path: '/allOwns',
      name: 'allOwns',
      component: () => import(/* webpackChunkName: "about" */ '@/views/binds/AllOwns.vue')
    },
    {
      path: '/notActive',
      name: 'notActive',
      component: () => import(/* webpackChunkName: "about" */ '@/views/binds/NotActive.vue')
    },
    {
      path: '/bindRequestWaiting',
      name: 'bindRequestWaiting',
      component: () => import(/* webpackChunkName: "about" */ '@/views/binds/BindRequestWaiting.vue')
    },
    {
      path: '/requesting',
      name: 'requesting',
      component: () => import(/* webpackChunkName: "about" */ '@/views/binds/Requesting.vue')
    },
    {
      path: '/binding',
      name: 'binding',
      component: () => import(/* webpackChunkName: "about" */ '@/views/binds/Binding.vue')
    },
    {
      path: '/expired',
      name: 'expired',
      component: () => import(/* webpackChunkName: "about" */ '@/views/binds/Expired.vue')
    },
    {
      path: '/purchased/:internalPaymentID',
      name: 'purchased',
      component: () => import(/* webpackChunkName: "about" */ '@/views/binds/Purchased.vue')
    },
  ]
}