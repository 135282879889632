<template>
  <div class="home">
    <v-container v-if="$isLogin && $isEmailVerified">
      <v-row>
        <v-col cols="12" sm="6" md="4" lg="3">
          <BindsFront/>
        </v-col>

        <v-col cols="12" sm="6" md="4" lg="3">
          <PurchaseFront
            :routeAdding="[]"
          />
        </v-col>

        <v-col cols="12" sm="6" md="4" lg="3">
          <AccountFront/>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
/* eslint no-unused-vars: 0 */

import BindsFront from "./binds/BindsFront"
//import PurchaseFront from "./purchase/PurchaseFront"
//import AccountFront from "./account/AccountFront"
import {AccountFrontCDN  as AccountFront} from 'vue-faui-user-fe2/accountCDN.js'
import {PurchaseFrontCDN as PurchaseFront} from 'vue-faui-user-fe2/purchaseCDN.js'

export default {
  name: 'home',
  components: {
    BindsFront,
    PurchaseFront,
    AccountFront,
  },
  data(){
    return {
    }
  },
}
</script>
