import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

import Document from '@/router/document.route.js'
import Binds    from '@/views/binds/binds.route.js'
//import Account  from '@/views/account/account.route.js'
//import Purchase from '@/views/purchase/purchase.route.js'

import {AccountRoutesCDN  as AccountRoutes} from 'vue-faui-user-fe2/accountCDN.js'
import {PurchaseRoutesCDN as PurchaseRoutes} from 'vue-faui-user-fe2/purchaseCDN.js'

import Keroyon from '@/views/Keroyon.vue'

import ga from 'vue-ga'

Vue.use(VueRouter)

const purchaseRoutes = [{ icon: '', text: 'けろよん', route: '/keroyon'},
                        { icon: '', text: 'ばははい', route: '/keroyon'}
]


const routes = [
  // home
  {
    path: '/',
    name: 'home',
    component: Home
  },

  // keroyon
  {
    path: '/keroyon',
    name: 'keroyon',
    component: Keroyon
  },

  // binds
  ...Binds.routes,

  // purchase
  //...Purchase.routes,

  // account
  //...Account.routes,

  // document
  ...Document.routes,

  // bind
  {
    path: '/bind/:bid',
    name: 'bind',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Bind.vue')
  },

  ...AccountRoutes.routes,
  ...PurchaseRoutes.routes.map(
    (e)=>{
      if (e.name == "purchase"){
        e.props = { routeAdding: purchaseRoutes, routeDeleting: ["/purchaseBind"] }
//        e.props = { routeAdding: purchaseRoutes}
      }
      return e
    })
]

/*
for (let elem of routes) {
  if (elem.name == "purchase") {
    elem.props = { route: [{ icon: '', text: 'けろよん', route: '/keroyon'},
                           { icon: '', text: 'ばははい', route: '/keroyon'}
                          ]
                  }
  }
}
*/

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

ga(router, 'UA-43410269-6')

export default router
