<template>
  <v-app>
    <Navbar :links="links">
      <template v-slot:title>
        <v-toolbar-title class="grey--text">
          <span class="font-weight-light">Koshinto </span>
          <span class="caption" >庚申塔</span>
        </v-toolbar-title>
      </template>
      <template v-slot:menu>
        <v-spacer/>
        <!-- logged in icon -->
        <v-tooltip v-if="$isLogin" bottom>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" class="grey--text">person_outline</v-icon>
          </template>
          <span>Logged in as {{ $displayName }}</span>
        </v-tooltip>

        <!-- dropdown menu -->
        <v-menu v-if="!$vuetify.breakpoint.xs" offset-y>
          <template v-slot:activator="{ on }">
          <!-- <v-btn text slot="activator"> -->
          <v-btn text v-on="on">
            <v-icon left class="grey--text">expand_more</v-icon>
            <span class="grey--text">Menu</span>
          </v-btn>
          </template>
          <v-list>
            <!-- eslint-disable -->
            <v-list-item v-for="link in links" :key="link.text" router :to="link.route">
              <v-list-item-title>{{ link.text }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-btn text class="grey--text" v-if="$route.name != 'home'" @click="$router.back()">
          <v-icon>arrow_back</v-icon><!--<span>Back</span>-->
        </v-btn>
      </template>
    </Navbar>
    <div id="nav">
      <Login/>
    </div>

    <v-content>
      <router-view/>
    </v-content>

    <v-footer
      padless
      height="1px"
      class="grey lighten-5"
    >
      <v-col
        class="text-center"
      >
        <p class="grey--text caption">
          &copy; 2020 - <a href="https://atelierueda.uedasoft.com/">Atelier UEDA</a>
        </p>
      </v-col>
    </v-footer>    
  </v-app>
</template>

<script>
//import Navbar from '@/components/Navbar'
//import Login from '@/components/Login.vue'
import {Navbar} from 'vuetify-nav'
import {AccountRoutesCDN  as AccountRoutes} from 'vue-faui-user-fe2/accountCDN.js'
import {PurchaseRoutes as PurchaseRoutes} from 'vue-faui-user-fe2/purchase.js'
import {LoginCDN as Login} from 'vue-faui-user-fe2/loginCDN'

//var fernet = require('fernet');
//var secret = new fernet.Secret("cw_0x689RpI-jtRR7oE8h_eQsKImvJapLeSbXpwF4e4=");
//console.log(secret)
/*var token = new fernet.Token({
  secret: secret,
  time: Date.parse(1),
  iv: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
})
console.log(token.encode("abc"))*/

export default {
  name: 'App',

  components: {
    Navbar,
    Login,
  },

  data: () => ({
    //
    links: [
      { icon: 'home', text: 'Home', route: '/'},
      { icon: 'list', text: 'Binds', route: '/binds'},
      PurchaseRoutes.menuItem,
      AccountRoutes.menuItem,
      { icon: 'menu_book', text: 'Document', route: '/docs'}
    ]
  }),
  computed: {
  }
};
</script>
